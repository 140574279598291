import PageContent from "components/PageContent";
import React from "react";
import { ExplainWorkbookFeatureNav } from "../ExplainWorkbook";
import { ExplainWorkbookDetails_getExplainWorkbookDetails as ExplainWorkbookType } from "../ExplainWorkbook/types/ExplainWorkbookDetails";
import Panel from "components/Panel";
import { useParams } from "react-router-dom";
import PanelSection from "components/PanelSection";
import { ExplainComparisonWorkbookNoFixedPlan } from "components/ExplainPanel/ExplainComparison";
import WithExplainPlan from "components/WithExplainPlan";
import WithNodeSelection from "components/Explain/WithNodeSelection";
import { useQuery } from "@apollo/client";

import QUERY from "./Query.graphql";
import Loading from "components/Loading";
import ExplainCompareSidebar from "components/ExplainCompareSidebar";

const ComparePlansPanel = ({ workbook }: { workbook: ExplainWorkbookType }) => {
  const { databaseId } = useParams();
  const { data, loading, error } = useQuery(QUERY, {
    variables: {
      databaseId,
    },
  });

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const blockSize = data.getServerDetails.blockSize;

  return (
    <WithExplainPlan>
      <WithNodeSelection>
        <PageContent
          windowTitle={`EXPLAIN Workbook: ${workbook.name}`}
          title={workbook.name}
          pageCategory="explains"
          pageName="workbooks"
          layout="sidebar"
          featureNav={
            <ExplainWorkbookFeatureNav
              workbookId={workbook.id}
              databaseId={databaseId}
            />
          }
        >
          <Panel title="Comparison">
            <PanelSection>
              <ExplainComparisonWorkbookNoFixedPlan
                databaseId={databaseId}
                workbookId={workbook.id}
                blockSize={blockSize}
              />
            </PanelSection>
          </Panel>
          <div className="w-[400px]">
            <ExplainCompareSidebar
              databaseId={databaseId}
              blockSize={blockSize}
            />
          </div>
        </PageContent>
      </WithNodeSelection>
    </WithExplainPlan>
  );
};

export default ComparePlansPanel;
