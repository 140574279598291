export type BitString = {
  bsval: string;
};
export type QueryParameterValue = number | string | boolean | BitString | null;
// { "$alias1": "foo", "$alias2": 123 }
export type AliasParamMapType = {
  [key: string]: QueryParameterValue;
};
// { 1: "foo", 2: 123 } (coming from query samples)
export type JsonParametersType = {
  [key: string]: QueryParameterValue;
};
// { "$1": "$alias1", "$2", "$alias2" }
export type ParamRefAliasMapType = {
  [key: string]: string;
};

export function jsonParametersToString(
  params: JsonParametersType | AliasParamMapType,
  aliasMap: ParamRefAliasMapType,
  usedAlias?: string[],
) {
  return Object.entries(params)
    .map(([key, value]) => {
      const aliasKey = aliasMap ? aliasMap[`$${key}`] : key;
      if (usedAlias == null || usedAlias.includes(aliasKey)) {
        return `${aliasKey} = ${stringifyValue(value)}`;
      }
      return null;
    })
    .filter(Boolean)
    .join(", ");
}

export function stringifyValue(value: any) {
  const maxLength = 50;

  if (value === null) {
    return "NULL";
  } else if (typeof value === "number") {
    return `${value}`;
  } else if (typeof value === "boolean") {
    return (value as boolean).toString().toUpperCase();
  } else if (typeof value === "string") {
    if (value.length > maxLength) {
      return `'${value.slice(0, maxLength)}...'`;
    } else {
      return `'${value}'`;
    }
  } else {
    // BitString
    return `${(value as BitString).bsval[0]}'${(value as BitString).bsval.slice(
      1,
    )}'`;
  }
}

export function renderKeyValueForParam(key: string, value: any) {
  const maxLength = 50;

  if (value === null) {
    return `${key} = NULL`;
  } else if (typeof value === "number") {
    return `${key} = ${value}`;
  } else if (typeof value === "boolean") {
    return `${key} = ${(value as boolean).toString().toUpperCase()}`;
  } else if (typeof value === "string") {
    if (value.length > maxLength) {
      return `${key} = '${value.slice(0, maxLength)}...'`;
    } else {
      return `${key} = '${value}'`;
    }
  } else {
    // BitString
    return `${(value as BitString).bsval[0]}'${(value as BitString).bsval.slice(
      1,
    )}'`;
  }
}

export function convertParamValue(value: string) {
  const numValue = +value;
  let convertedValue: any = value;
  if (value === "null" || value === "NULL") {
    convertedValue = null;
  } else if (value == "empty_str") {
    convertedValue = "";
  } else if (!isNaN(numValue)) {
    convertedValue = numValue;
  } else if (value === "true") {
    convertedValue = true;
  } else if (value === "false") {
    convertedValue = false;
  } else {
    // remove single quote if present
    if (value.startsWith("'") && value.endsWith("'")) {
      convertedValue = value.slice(1, -1);
    }
  }
  return convertedValue;
}
