import React from "react";
import classNames from "classnames";
import moment from "moment-timezone";

import {
  formatNumber,
  formatBytes,
  formatMs,
  formatPercent,
  formatTimestampShort,
} from "utils/format";

import {
  ExplainComparison as ExplainComparisonType,
  ExplainComparison_getQueryExplains as ExplainSummary,
  ExplainComparisonVariables,
} from "./types/ExplainComparison";
import {
  ExplainComparisonWorkbook as ExplainComparisonWorkbookType,
  ExplainComparisonWorkbookVariables,
  ExplainComparisonWorkbook_getExplainWorkbookDetails as ExplainComparisonWorkbookDetailsType,
} from "./types/ExplainComparisonWorkbook";

import { AnnotatedPlan } from "types/explain";
import ExplainFingerprint from "components/ExplainFingerprint";
import PanelTable from "components/PanelTable";

import QUERY from "./Query.graphql";
import QUERY_WORKBOOK from "./Query.workbook.graphql";

import styles from "./style.module.scss";
import { useQuery } from "@apollo/client";
import Loading from "components/Loading";
import { ExplainPlanType } from "components/Explain/util";
import {
  ExplainCostMetric,
  useCostMetric,
  useSetCostMetric,
  WithExplainCostMetric,
} from "components/WithExplainCostMetric";
import ExplainDiff from "components/ExplainDiff";
import { useFeature } from "components/OrganizationFeatures";
import {
  ComparablePlanType,
  useCurrentComparePlan,
  useCurrentPlan,
  useSetCurrentComparePlan,
  useSetCurrentPlan,
} from "components/WithExplainPlan";

const ExplainComparison: React.FunctionComponent<{
  databaseId: string;
  explain: ExplainPlanType;
  plan: AnnotatedPlan;
  blockSize: number;
  workbookId?: string;
}> = ({ databaseId, blockSize, explain, plan, workbookId }) => {
  const hasExplainCompareFeature = useFeature("explainCompare");
  if (hasExplainCompareFeature || workbookId) {
    return (
      <ExplainComparisonNew
        databaseId={databaseId}
        blockSize={blockSize}
        explain={explain}
        plan={plan}
        workbookId={workbookId}
      />
    );
  } else {
    return (
      <ExplainComparisonOld
        databaseId={databaseId}
        blockSize={blockSize}
        explain={explain}
        plan={plan}
      />
    );
  }
};

const ExplainComparisonNew: React.FunctionComponent<{
  databaseId: string;
  blockSize: number;
  explain: ExplainPlanType;
  plan: AnnotatedPlan;
  workbookId?: string;
}> = ({ databaseId, blockSize, explain, plan, workbookId }) => {
  // TODO: Rework the data fetching for both of these to only load the annotated JSON for the plans being compared
  if (workbookId) {
    return (
      <ExplainComparisonWorkbook
        databaseId={databaseId}
        blockSize={blockSize}
        explain={explain}
        plan={plan}
        workbookId={workbookId}
      />
    );
  } else {
    return (
      <ExplainComparisonQueryPage
        databaseId={databaseId}
        blockSize={blockSize}
        explain={explain}
        plan={plan}
      />
    );
  }
};

const ExplainComparisonWorkbook: React.FunctionComponent<{
  databaseId: string;
  blockSize: number;
  explain: ExplainPlanType;
  plan: AnnotatedPlan;
  workbookId: string;
}> = ({ explain, blockSize, plan, workbookId }) => {
  const { error, loading, data } = useQuery<
    ExplainComparisonWorkbookType,
    ExplainComparisonWorkbookVariables
  >(QUERY_WORKBOOK, {
    variables: {
      workbookId,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }
  let ourLabel = "";
  const comparablePlans: ComparablePlanType[] = [];
  [data.getExplainWorkbookDetails.baselineQuery]
    .concat(data.getExplainWorkbookDetails.explainQueries)
    .forEach((explainQuery) =>
      explainQuery.explainResults.forEach((e) => {
        const label = `${
          explainQuery.name
        } - Parameter Set ${getParameterSetName(
          e.parameterSetId,
          data.getExplainWorkbookDetails,
        )} - ${e.planFingerprint.substring(0, 7)} - runtime: ${
          e.runtimeMs ? formatMs(e.runtimeMs) : "n/a"
        } - I/O read time: ${formatMs(e.totalBlkReadTime)}`;
        if (e.id == explain.id) {
          ourLabel = label;
        } else {
          comparablePlans.push({
            id: e.id,
            label,
            seenAt: e.createdAt,
            fingerprint: e.planFingerprint,
            runtime: e.runtimeMs,
            ioBytes: e.totalSharedBlksRead * blockSize,
            ioMs: e.totalBlkReadTime,
            totCost: e.totalCost,
            plan: JSON.parse(e.annotatedJson),
          });
        }
      }),
    );
  const ourPlan: ComparablePlanType = {
    id: explain.id,
    seenAt: explain.seenAt,
    label: ourLabel,
    fingerprint: explain.fingerprint,
    runtime: explain.querySample.runtimeMs,
    ioBytes: explain.totalSharedBlksRead * blockSize,
    ioMs: explain.totalBlkReadTime,
    totCost: explain.totalCost,
    plan,
  };

  return (
    <ExplainComparisonImpl
      ourPlan={ourPlan}
      ourLabel={ourLabel}
      comparablePlans={comparablePlans}
    />
  );
};

function getParameterSetName(
  parameterSetId: string,
  workbookDetails: ExplainComparisonWorkbookDetailsType,
): string {
  return (
    workbookDetails.parameterSets.find((set) => set.id === parameterSetId)
      ?.name ?? parameterSetId
  );
}

const ExplainComparisonQueryPage: React.FunctionComponent<{
  databaseId: string;
  blockSize: number;
  explain: ExplainPlanType;
  plan: AnnotatedPlan;
}> = ({ databaseId, blockSize, explain, plan }) => {
  const { error, loading, data } = useQuery<
    ExplainComparisonType,
    ExplainComparisonVariables
  >(QUERY, {
    variables: {
      queryId: explain.query.id,
      databaseId,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }
  const ourLabel = `${formatTimestampShort(
    moment.unix(explain.seenAt),
  )} - ${explain.fingerprint.substring(0, 7)} - runtime: ${formatMs(
    explain.querySample.runtimeMs,
  )} - I/O read time: ${formatMs(explain.totalBlkReadTime)}`;
  const ourPlan = {
    id: explain.id,
    seenAt: explain.seenAt,
    fingerprint: explain.fingerprint,
    label: ourLabel,
    runtime: explain.querySample.runtimeMs,
    ioMs: explain.totalBlkReadTime,
    ioBytes: explain.totalSharedBlksRead * blockSize,
    totCost: explain.totalCost,
    plan,
  };
  const comparablePlans = data.getQueryExplains
    .filter((e) => e.id != explain.id)
    .map((e) => ({
      id: e.id,
      seenAt: e.seenAt,
      fingerprint: e.fingerprint,
      label: `${formatTimestampShort(
        moment.unix(e.seenAt),
      )} - ${e.fingerprint.substring(0, 7)} - runtime: ${formatMs(
        e.querySample.runtimeMs,
      )} - I/O read time: ${formatMs(e.totalBlkReadTime)}`,
      runtime: e.querySample.runtimeMs,
      ioMs: e.totalBlkReadTime,
      ioBytes: e.totalSharedBlksRead * blockSize,
      totCost: e.totalCost,
      plan: JSON.parse(e.annotatedJson),
    }));
  return (
    <ExplainComparisonImpl
      ourPlan={ourPlan}
      ourLabel={ourLabel}
      comparablePlans={comparablePlans}
    />
  );
};

const ExplainComparisonImpl: React.FunctionComponent<{
  ourPlan: ComparablePlanType;
  ourLabel: string;
  comparablePlans: ComparablePlanType[];
}> = ({ ourPlan, ourLabel, comparablePlans }) => {
  const otherPlan = useCurrentComparePlan();
  const setComparePlan = useSetCurrentComparePlan();
  function handleComparePlanChange(evt: React.ChangeEvent<HTMLSelectElement>) {
    const otherPlanId = evt.currentTarget.value;
    const otherPlan = comparablePlans.find((e) => e.id == otherPlanId);
    setComparePlan(otherPlan);
  }
  return (
    <div>
      <form>
        <div className="grid grid-cols-[60px_minmax(900px,_1fr)] gap-2 text-base">
          <div className="font-semibold">Plan A:</div>
          <div>{ourLabel}</div>
          <div className="font-semibold">Plan B:</div>
          <div>
            <select onChange={handleComparePlanChange} defaultValue="">
              <option disabled value="">
                {" "}
                -- select a plan to compare with --{" "}
              </option>
              {comparablePlans.map((e) => (
                <option value={e.id} key={e.id}>
                  {e.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </form>
      {otherPlan && (
        <WithExplainCostMetric>
          <ExplainDiffAndHeader plan1={ourPlan.plan} plan2={otherPlan.plan} />
        </WithExplainCostMetric>
      )}
    </div>
  );
};

const ExplainComparisonImpl2: React.FunctionComponent<{
  comparablePlans: ComparablePlanType[];
}> = ({ comparablePlans }) => {
  const ourPlan = useCurrentPlan();
  const setCurrentPlan = useSetCurrentPlan();
  const otherPlan = useCurrentComparePlan();
  const setComparePlan = useSetCurrentComparePlan();
  function handleComparePlanChange(evt: React.ChangeEvent<HTMLSelectElement>) {
    const otherPlanId = evt.currentTarget.value;
    const otherPlan = comparablePlans.find((e) => e.id == otherPlanId);
    setComparePlan(otherPlan);
  }
  function handleMainPlanChange(evt: React.ChangeEvent<HTMLSelectElement>) {
    const mainPlanId = evt.currentTarget.value;
    const mainPlan = comparablePlans.find((e) => e.id == mainPlanId);
    setCurrentPlan(mainPlan);
  }
  return (
    <div>
      <form>
        <div className="grid grid-cols-[60px_minmax(900px,_1fr)] gap-2 text-base">
          <div className="font-semibold">Plan A:</div>
          <div>
            <select onChange={handleMainPlanChange} defaultValue="">
              <option disabled value="">
                {" "}
                -- select a plan --{" "}
              </option>
              {comparablePlans.map((e) => (
                <option value={e.id} key={e.id}>
                  {e.label}
                </option>
              ))}
            </select>
          </div>
          <div className="font-semibold">Plan B:</div>
          <div>
            <select onChange={handleComparePlanChange} defaultValue="">
              <option disabled value="">
                {" "}
                -- select a different plan to compare with --{" "}
              </option>
              {comparablePlans.map((e) => (
                <option value={e.id} key={e.id}>
                  {e.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </form>
      {otherPlan && (
        <WithExplainCostMetric>
          <ExplainDiffAndHeader plan1={ourPlan.plan} plan2={otherPlan.plan} />
        </WithExplainCostMetric>
      )}
    </div>
  );
};

function ExplainDiffAndHeader({
  plan1,
  plan2,
}: {
  plan1: AnnotatedPlan;
  plan2: AnnotatedPlan;
}) {
  const costMetric = useCostMetric();
  const setCostMetric = useSetCostMetric();
  function handleMetricChange(evt: React.ChangeEvent<HTMLInputElement>) {
    setCostMetric(evt.currentTarget.value as ExplainCostMetric);
  }
  return (
    <div>
      <div className="flex gap-2 items-baseline justify-end">
        <b>Cost Metric:</b>
        <label>
          <input
            className="!mr-1"
            type="radio"
            value="Est. Cost"
            checked={costMetric == "Est. Cost"}
            onChange={handleMetricChange}
          />
          Est. Total Cost (Self)
        </label>
        <label>
          <input
            className="!mr-1"
            type="radio"
            value="Runtime"
            checked={costMetric == "Runtime"}
            onChange={handleMetricChange}
          />
          Runtime (Self)
        </label>
        <label>
          <input
            className="!mr-1"
            type="radio"
            value="I/O Time"
            checked={costMetric == "I/O Time"}
            onChange={handleMetricChange}
          />
          I/O Read Time (Self)
        </label>
        <label>
          <input
            className="!mr-1"
            type="radio"
            value="Rows"
            checked={costMetric == "Rows"}
            onChange={handleMetricChange}
          />
          Rows
        </label>
      </div>
      <ExplainDiff plan1={plan1} plan2={plan2} />
    </div>
  );
}

export const ExplainComparisonWorkbookNoFixedPlan: React.FunctionComponent<{
  databaseId: string;
  blockSize: number;
  workbookId: string;
}> = ({ workbookId, blockSize }) => {
  const { error, loading, data } = useQuery<
    ExplainComparisonWorkbookType,
    ExplainComparisonWorkbookVariables
  >(QUERY_WORKBOOK, {
    variables: {
      workbookId,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }
  const comparablePlans: ComparablePlanType[] = [];
  [data.getExplainWorkbookDetails.baselineQuery]
    .concat(data.getExplainWorkbookDetails.explainQueries)
    .forEach((explainQuery) =>
      explainQuery.explainResults.forEach((e) => {
        const label = `${
          explainQuery.name
        } - Parameter Set ${getParameterSetName(
          e.parameterSetId,
          data.getExplainWorkbookDetails,
        )} - ${e.planFingerprint.substring(0, 7)} - runtime: ${
          e.runtimeMs ? formatMs(e.runtimeMs) : "n/a"
        } - I/O read time: ${formatMs(e.totalBlkReadTime)}`;
        comparablePlans.push({
          id: e.id,
          label,
          seenAt: e.createdAt,
          fingerprint: e.planFingerprint,
          runtime: e.runtimeMs,
          ioMs: e.totalBlkReadTime,
          ioBytes: e.totalSharedBlksRead * blockSize,
          totCost: e.totalCost,
          plan: JSON.parse(e.annotatedJson),
        });
      }),
    );

  return <ExplainComparisonImpl2 comparablePlans={comparablePlans} />;
};

const ExplainComparisonOld: React.FunctionComponent<{
  databaseId: string;
  explain: ExplainPlanType;
  plan: AnnotatedPlan;
  blockSize: number;
}> = ({ databaseId, blockSize, explain, plan }) => {
  const { error, loading, data } = useQuery<
    ExplainComparisonType,
    ExplainComparisonVariables
  >(QUERY, {
    variables: {
      queryId: explain.query.id,
      databaseId,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }
  const explains = data.getQueryExplains;
  const explainsWithQuerySample = explains.filter((e) => e.querySample);
  return (
    <ExplainComparisonView
      ioBlocks={explain.totalSharedBlksRead}
      ioMs={explain.totalBlkReadTime}
      fingerprint={explain.fingerprint}
      plan={plan}
      current={explain}
      explains={explainsWithQuerySample}
      runtime={explain.querySample.runtimeMs}
      blockSize={blockSize}
      seenAt={explain.seenAt}
    />
  );
};

type Props = {
  fingerprint: string;
  runtime: number;
  ioBlocks: number;
  blockSize: number;
  ioMs: number;
  plan: AnnotatedPlan;
  explains: ExplainSummary[];
  seenAt: number;
  current: ExplainPlanType;
};

const ExplainComparisonView: React.FunctionComponent<Props> = ({
  plan,
  fingerprint,
  runtime,
  ioBlocks,
  blockSize,
  ioMs,
  explains,
  seenAt,
  current,
}) => {
  const root = plan.plan[0].Plan;
  const totCost =
    "Total Cost" in root ? formatNumber(root["Total Cost"]) : "N/A";

  // TODO
  //  * if this execution is not in the plans list, don't show comparisons
  //  * if this execution is the only plan with *this* fingerprint, don't show comparisons with--well basically with itself
  //  * if there is only one other plan with same fingerprint, don't show min/max
  //  * if there is only one other plan with *different* fingerprint, don't show min/max

  const thisPlanExplains = explains.filter(
    (e) => e.fingerprint === fingerprint,
  );
  const thisPlanSummary = getExplainsStats(thisPlanExplains);

  const otherPlanExplains = explains.filter(
    (e) => e.fingerprint !== fingerprint,
  );
  const otherPlanSummary = getExplainsStats(otherPlanExplains);

  const hasAnyOtherPlans = explains.length > 0;
  return (
    <PanelTable borders={true} className={styles.explainComparison}>
      {hasAnyOtherPlans && (
        <thead>
          <tr>
            <th />
            <th>This Execution</th>
            {thisPlanExplains.length > 0 && (
              <th colSpan={2}>
                This Plan{" · "}
                <ExplainFingerprint explain={current} />
              </th>
            )}
            {otherPlanExplains.length > 0 && <th colSpan={2}>Other Plans</th>}
          </tr>
          <tr>
            <th />
            <th>{formatTimestampShort(moment.unix(seenAt))}</th>
            {thisPlanExplains.length > 0 && (
              <>
                <th>min</th>
                <th>max</th>
              </>
            )}
            {otherPlanExplains.length > 0 && (
              <>
                <th>min</th>
                <th>max</th>
              </>
            )}
          </tr>
        </thead>
      )}
      <tbody>
        <tr>
          <th scope="row">Total Est. Cost</th>
          <td>{totCost}</td>
          {thisPlanExplains.length > 0 && (
            <>
              <td>{formatNumber(thisPlanSummary.totCost.min)}</td>
              <td>{formatNumber(thisPlanSummary.totCost.max)}</td>
            </>
          )}
          {otherPlanExplains.length > 0 && (
            <>
              <td>{formatNumber(otherPlanSummary.totCost.min)}</td>
              <td>{formatNumber(otherPlanSummary.totCost.max)}</td>
            </>
          )}
        </tr>
        <tr>
          <th scope="row">Runtime</th>
          <td>{formatMs(runtime)}</td>
          {thisPlanExplains.length > 0 && (
            <>
              <td>{formatMs(thisPlanSummary.runtime.min)}</td>
              <td>{formatMs(thisPlanSummary.runtime.max)}</td>
            </>
          )}
          {otherPlanExplains.length > 0 && (
            <>
              <td>{formatMs(otherPlanSummary.runtime.min)}</td>
              <td>{formatMs(otherPlanSummary.runtime.max)}</td>
            </>
          )}
        </tr>
        <tr>
          <th scope="row">Read From Disk</th>
          <td>{formatBlocksBytes(ioBlocks, blockSize)}</td>
          {thisPlanExplains.length > 0 && (
            <>
              <td>
                {formatBlocksBytes(thisPlanSummary.blksRead.min, blockSize)}
              </td>
              <td>
                {formatBlocksBytes(thisPlanSummary.blksRead.max, blockSize)}
              </td>
            </>
          )}
          {otherPlanExplains.length > 0 && (
            <>
              <td>
                {formatBlocksBytes(otherPlanSummary.blksRead.min, blockSize)}
              </td>
              <td>
                {formatBlocksBytes(otherPlanSummary.blksRead.max, blockSize)}
              </td>
            </>
          )}
        </tr>
        <tr>
          <th scope="row">I/O Read Time</th>
          <td>{formatIOReadTime(ioMs, ioMs / runtime)}</td>
          {thisPlanExplains.length > 0 && (
            <>
              <td>
                {formatIOReadTime(
                  thisPlanSummary.blkReadTime.min,
                  thisPlanSummary.blkReadTimeFract.min,
                )}
              </td>
              <td>
                {formatIOReadTime(
                  thisPlanSummary.blkReadTime.max,
                  thisPlanSummary.blkReadTimeFract.max,
                )}
              </td>
            </>
          )}
          {otherPlanExplains.length > 0 && (
            <>
              <td>
                {formatIOReadTime(
                  otherPlanSummary.blkReadTime.min,
                  otherPlanSummary.blkReadTimeFract.min,
                )}
              </td>
              <td>
                {formatIOReadTime(
                  otherPlanSummary.blkReadTime.max,
                  otherPlanSummary.blkReadTimeFract.max,
                )}
              </td>
            </>
          )}
        </tr>
      </tbody>
    </PanelTable>
  );
};

type Range = {
  min: number;
  max: number;
};

type ExplainStats = {
  totCost: Range;
  runtime: Range;
  blksRead: Range;
  blkReadTime: Range;
  blkReadTimeFract: Range;
};

const getExplainsStats = (explains: ExplainSummary[]): ExplainStats => {
  const totCosts = explains.map((e) => e.totalCost);
  const runtimes = explains.map((e) => e.querySample.runtimeMs);
  const blksRead = explains.map((e) => e.totalSharedBlksRead);

  type BlkTimeReduceState = {
    min: number;
    max: number;
    minRuntime: number;
    maxRuntime: number;
  };
  const blkReadTime = explains.reduce<BlkTimeReduceState>(
    (state, curr) => {
      const result = { ...state };
      if (curr.totalBlkReadTime > state.max) {
        Object.assign(result, {
          max: curr.totalBlkReadTime,
          maxRuntime: curr.querySample.runtimeMs,
        });
      }
      if (curr.totalBlkReadTime < state.min) {
        Object.assign(result, {
          min: curr.totalBlkReadTime,
          minRuntime: curr.querySample.runtimeMs,
        });
      }
      return result;
    },
    { min: Infinity, max: -1, minRuntime: Infinity, maxRuntime: -1 },
  );

  const min = (values: number[]): number => {
    return Math.min.apply(null, values);
  };
  const max = (values: number[]): number => {
    return Math.max.apply(null, values);
  };

  return {
    totCost: {
      min: min(totCosts),
      max: max(totCosts),
    },
    runtime: {
      min: min(runtimes),
      max: max(runtimes),
    },
    blksRead: {
      min: min(blksRead),
      max: max(blksRead),
    },
    blkReadTime: {
      min: blkReadTime.min,
      max: blkReadTime.max,
    },
    blkReadTimeFract: {
      min: blkReadTime.min / blkReadTime.minRuntime,
      max: blkReadTime.max / blkReadTime.maxRuntime,
    },
  };
};

const formatBlocksBytes = (blocks: number, blockSize: number): string => {
  return `${formatBytes(blocks * blockSize)} · ${formatNumber(blocks)} blocks`;
};

const formatIOReadTime = (
  ioMs: number,
  ioFract: number,
): React.ReactElement => {
  if (!ioMs) {
    return <>-</>;
  }

  return (
    <>
      <span>{formatMs(ioMs)}</span>
      <span>
        {" "}
        ·{" "}
        <span
          className={classNames({
            [styles.redHighlight]: ioFract > 0.5,
          })}
        >
          {formatPercent(ioFract)}
        </span>
      </span>
    </>
  );
};

export default ExplainComparison;
