import React, { useState } from "react";
import Panel from "components/Panel";
import {
  ExplainWorkbookDetails_getExplainWorkbookDetails as ExplainWorkbookType,
  ExplainWorkbookDetails_getExplainWorkbookDetails_explainQueries as ExplainQueryType,
} from "../ExplainWorkbook/types/ExplainWorkbookDetails";
import PanelSection from "components/PanelSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCaretDown } from "@fortawesome/pro-regular-svg-icons";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { faCircleCaretRight } from "@fortawesome/pro-regular-svg-icons";
import Button from "components/Button";
import UNSELECT_MUTATION from "./Mutation.unselect.graphql";
import CREATE_MUTATION from "./Mutation.create.graphql";
import WORKBOOK_DETAIL_QUERY from "../ExplainWorkbook/Query.graphql";
import { useMutation } from "@apollo/client";
import {
  UpdateExplainParameterSetsSelected,
  UpdateExplainParameterSetsSelectedVariables,
} from "./types/UpdateExplainParameterSetsSelected";
import CopyToClipboard from "components/CopyToClipboard";
import SQL from "components/SQL";
import { jsonParametersToString } from "../ExplainWorkbook/util";
import {
  CreateExplainResult,
  CreateExplainResultVariables,
} from "./types/CreateExplainResult";
import { useNavigate, useParams } from "react-router-dom";
import { useRoutes } from "utils/routes";
import Grid from "components/Grid";
import ExpandableSQL from "components/ExpandableSQL";
import Callout from "components/Callout";
import CopyCodeBlock from "components/CopyCodeBlock";
import PageContent from "components/PageContent";

type RunMethodType = "manual" | "collector";

const RunExplain = ({
  workbook,
  explainQuery,
}: {
  workbook: ExplainWorkbookType;
  explainQuery: ExplainQueryType;
}) => {
  const [runMethod, setRunMethod] = useState<RunMethodType>("manual");

  return (
    <PageContent
      windowTitle={`EXPLAIN Workbook: ${workbook.name}`}
      featureInfo={
        <RunExplainTitle
          workbookName={workbook.name}
          variantName={explainQuery.name}
        />
      }
      pageCategory="explains"
      pageName="workbooks"
      layout="sidebar"
    >
      <RunExplainContent
        runMethod={runMethod}
        setRunMethod={setRunMethod}
        workbook={workbook}
        explainQuery={explainQuery}
      />
      {runMethod === "manual" ? (
        <RunExplainManualSidebar />
      ) : (
        <div className="w-[320px]" />
      )}
    </PageContent>
  );
};

const RunExplainContent = ({
  workbook,
  runMethod,
  setRunMethod,
  explainQuery,
}: {
  workbook: ExplainWorkbookType;
  runMethod: RunMethodType;
  setRunMethod: React.Dispatch<RunMethodType>;
  explainQuery: ExplainQueryType;
}) => {
  switch (runMethod) {
    case "manual":
      return (
        <RunExplainManual
          workbook={workbook}
          setRunMethod={setRunMethod}
          explainQuery={explainQuery}
        />
      );
    case "collector":
      return (
        <RunExplainCollector
          workbook={workbook}
          setRunMethod={setRunMethod}
          explainQuery={explainQuery}
        />
      );
  }
};

const RunExplainManual = ({
  workbook,
  setRunMethod,
  explainQuery,
}: {
  workbook: ExplainWorkbookType;
  setRunMethod: React.Dispatch<RunMethodType>;
  explainQuery: ExplainQueryType;
}) => {
  const navigate = useNavigate();
  const { databaseId } = useParams();
  const [explainResult, setExplainResult] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { databaseWorkbookVariant, databaseWorkbook } = useRoutes();

  // Set the result list length as an initial state in case a user leaves the page in the middle of adding results
  // (e.g. if 1 result is already there out of 3, currentParamSet becomes 1 and will start with the second param set)
  const [currentParamSet, setCurrentParamSet] = useState(
    explainQuery.explainResults.length,
  );

  const [createExplainResult] = useMutation<
    CreateExplainResult,
    CreateExplainResultVariables
  >(CREATE_MUTATION);

  const [updateExplainWorkbook] = useMutation<
    UpdateExplainParameterSetsSelected,
    UpdateExplainParameterSetsSelectedVariables
  >(UNSELECT_MUTATION);

  // Only allow with the initial workbook creation flow to avoid all edge cases
  // Also only allow this when no results is uploaded yet
  const allowCancelParameterSetSelect =
    workbook.baselineQuery.id === explainQuery.id &&
    explainQuery.explainResults.length === 0;

  // TODO: disable switching to the collector for param set 2+?
  // or maybe only run with collector for remaining param sets
  const secondaryTitle = (
    <div>
      <Button
        bare
        className="!text-[#337ab7] hover:!text-[#23527c]"
        onClick={() => setRunMethod("collector")}
      >
        Switch to Collector workflow
      </Button>
    </div>
  );
  // TODO: change command based on preference (add verbose? maybe checkbox to select?)
  // switch format too
  const explainCommand = "EXPLAIN (ANALYZE, VERBOSE, BUFFERS, FORMAT JSON)\n";
  const explainWithQueryText = `${explainCommand}${explainQuery.queryText}${
    explainQuery.queryText.endsWith(";") ? "" : ";"
  }`;
  const showNextButton =
    workbook.parameterSets.length > 1 &&
    workbook.parameterSets.length > currentParamSet + 1;

  const handleSubmitCurrentSet = () => {
    if (explainResult === "") {
      setErrorMessage("Explain result is required");
      return;
    }
    let format = "json";
    try {
      JSON.parse(explainResult);
    } catch {
      format = "text";
    }
    const parameterSetId =
      explainQuery.queryTextWithParameters[currentParamSet]?.parameterSetId;
    createExplainResult({
      variables: {
        explainQueryId: explainQuery.id,
        explainJson: format === "json" ? explainResult : "",
        explainText: format === "text" ? explainResult : "",
        parameterSetId: parameterSetId,
      },
      onCompleted: () => {
        setErrorMessage("");
        setExplainResult("");
        setCurrentParamSet(currentParamSet + 1);
        if (!showNextButton) {
          navigate(
            databaseWorkbookVariant(
              workbook.databaseId,
              workbook.id,
              explainQuery.id,
            ),
          );
        }
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  const handleBackToParameterSetSelection = () => {
    updateExplainWorkbook({
      variables: {
        workbookId: workbook.id,
        parameterSetsSelected: false,
      },
      refetchQueries: [
        {
          query: WORKBOOK_DETAIL_QUERY,
          variables: {
            workbookId: workbook.id,
            databaseId,
          },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        navigate(databaseWorkbook(databaseId, workbook.id));
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  return (
    <Panel title="Run EXPLAIN ANALYZE" secondaryTitle={secondaryTitle}>
      {workbook.parameterSets.length === 0 && (
        <PanelSection>
          <div className="my-1 font-medium">Command</div>
          <div className="relative">
            <pre className="max-h-[200px] overflow-auto">
              <code>
                <SQL sql={explainWithQueryText} />
              </code>
            </pre>
            <CopyToClipboard
              className="absolute top-1 right-2 text-[12px] text-[#999]"
              label="copy"
              content={explainWithQueryText}
            />
          </div>
          <div className="grid grid-cols-2 mt-4">
            <div className="mb-1 font-medium">EXPLAIN output</div>
            <div className="mb-1 justify-self-end text-[#606060]">
              Text or JSON format supported
            </div>
            <div className="col-span-2">
              <textarea
                rows={15}
                className="bg-white rounded border border-gray-300 box-border w-full leading-5 px-2 py-1.5 disabled:bg-[#eee]"
                onChange={(e) => setExplainResult(e.target.value)}
                value={explainResult}
                placeholder="Paste EXPLAIN output here..."
              />
            </div>
          </div>
          <div className="mt-2 text-[#FF0000]">{errorMessage}</div>
          <div className="text-right mt-4">
            <button
              className="btn btn-success !w-[140px]"
              onClick={handleSubmitCurrentSet}
            >
              Submit
            </button>
          </div>
        </PanelSection>
      )}
      {workbook.parameterSets.map((paramSet, idx) => {
        const currentQuery =
          explainQuery.queryTextWithParameters[currentParamSet]
            .queryWithParameters;
        const explainQueryText = `${explainCommand}${currentQuery}${
          currentQuery.endsWith(";") ? "" : ";"
        }`;
        const focused = currentParamSet === idx;
        const uploaded = idx < currentParamSet;
        const icon = focused
          ? faCircleCaretDown
          : uploaded
          ? faCheckCircle
          : faCircleCaretRight;
        const iconClass = uploaded ? "text-[#5CB85C]" : undefined;
        return (
          <PanelSection key={paramSet.id}>
            <div className="font-medium align-middle">
              <FontAwesomeIcon icon={icon} className={iconClass} /> EXPLAIN for{" "}
              {paramSet.name}
            </div>
            {focused && (
              <>
                <div className="my-1 font-medium">Command</div>
                <div className="relative">
                  <pre className="max-h-[200px] overflow-auto">
                    <code>
                      <SQL sql={explainQueryText} />
                    </code>
                  </pre>
                  <CopyToClipboard
                    className="absolute top-1 right-2 text-[12px] text-[#999]"
                    label="copy"
                    content={explainQueryText}
                  />
                </div>
                <div className="grid grid-cols-2 mt-4">
                  <div className="mb-1 font-medium">EXPLAIN output</div>
                  <div className="mb-1 justify-self-end text-[#606060]">
                    Text or JSON format supported
                  </div>
                  <div className="col-span-2">
                    <textarea
                      rows={15}
                      className="bg-white rounded border border-gray-300 box-border w-full leading-5 px-2 py-1.5 disabled:bg-[#eee]"
                      onChange={(e) => setExplainResult(e.target.value)}
                      value={explainResult}
                      placeholder="Paste EXPLAIN output here..."
                    />
                  </div>
                </div>
                <div className="mt-2 text-[#FF0000]">{errorMessage}</div>
                <div className="mt-4">
                  <button
                    className="btn btn-success !w-[140px]"
                    onClick={handleSubmitCurrentSet}
                  >
                    {showNextButton ? "Next Set..." : "Start exploring"}
                  </button>
                  {allowCancelParameterSetSelect && (
                    <Button
                      bare
                      onClick={handleBackToParameterSetSelection}
                      className="!px-5 !text-[#273858]"
                    >
                      Back to Parameter Sets Selection
                    </Button>
                  )}
                </div>
              </>
            )}
          </PanelSection>
        );
      })}
    </Panel>
  );
};

const RunExplainCollector = ({
  workbook,
  setRunMethod,
  explainQuery,
}: {
  workbook: ExplainWorkbookType;
  setRunMethod: React.Dispatch<RunMethodType>;
  explainQuery: ExplainQueryType;
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const secondaryTitle = (
    <div>
      <Button
        bare
        className="!text-[#337ab7] hover:!text-[#23527c]"
        onClick={() => setRunMethod("manual")}
      >
        Switch to Manual workflow
      </Button>
    </div>
  );
  const noParamSets = workbook.parameterSets.length === 0;

  return (
    <Panel title="Run EXPLAIN ANALYZE" secondaryTitle={secondaryTitle}>
      <PanelSection>
        <ExpandableSQL sql={explainQuery.queryTextWithAlias} />
        <div className="mt-4">
          Run EXPLAIN using the pganalyze collector
          {noParamSets ? "." : " with the following parameter sets:"}
        </div>
      </PanelSection>
      {!noParamSets && (
        <Grid
          className="grid-cols-1fr"
          data={workbook.aliasParamMapList}
          columns={[
            {
              field: "id",
              header: "Parameter Set",
              className: "whitespace-normal",
              renderer: function ParametersCell({ rowData }) {
                return (
                  <pre className="border-none m-0 p-0 bg-none bg-transparent whitespace-pre-wrap">
                    {jsonParametersToString(rowData.parameters, null)}
                  </pre>
                );
              },
              disableSort: true,
            },
          ]}
        />
      )}
      <PanelSection>
        <div className="mt-2 text-[#FF0000]">{errorMessage}</div>
        <div className="text-right mt-4">
          <button
            className="btn btn-success !w-[140px]"
            onClick={() => {
              setErrorMessage(
                "Running EXPLAIN with the collector is not supported yet",
              );
            }}
            disabled
          >
            Run
          </button>
        </div>
      </PanelSection>
    </Panel>
  );
};

const RunExplainManualSidebar = () => {
  return (
    <div className="w-[320px]">
      <Callout className="font-normal">
        <span className="font-medium">Run multiple times</span> (3-5) to account
        for caching.
        <br />
        <span className="font-medium">Warm up the cache:</span> Execute the
        query a few times beforehand.
        <br />
        <span className="font-medium">Use realistic data:</span> Match
        production size/distribution.
        <br />
        <span className="font-medium">Monitor variability:</span> Check for
        consistent vs. anomalous results.
      </Callout>
      <div className="my-3">
        <p className="font-medium">Prepping for EXPLAIN in psql</p>
        <p>Update settings for easier copy/pasting:</p>
        <CopyCodeBlock>
          \pset tuples_only true <br />
          \pset format unaligned
        </CopyCodeBlock>
        <p>
          You can also use <code>\t \a</code>{" "}
          <CopyToClipboard content={"\\t \\a"} /> instead.
        </p>
        <hr />
        <p>If you can use a clipboard, run the following:</p>
        <CopyCodeBlock>\o |pbcopy -b</CopyCodeBlock>
        <p>
          After running the EXPLAIN command, <code>\q</code> to copy results.
          The above <code>pbcopy</code> command is for macOS. For other systems,
          similar tools are available like <code>xsel</code> or{" "}
          <code>xclip</code>.
        </p>
      </div>
    </div>
  );
};

export const RunExplainTitle = ({
  workbookName,
  variantName,
}: {
  workbookName: string;
  variantName: string;
}) => {
  return (
    <div className="text-[#606060]">
      <h2 className="m-0 py-[9px] font-medium leading-[26px] text-[22px]">
        {workbookName}
      </h2>
      <div>Variant: {variantName}</div>
    </div>
  );
};

export default RunExplain;
