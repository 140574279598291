import React from "react";

import {
  formatNumber,
  formatBytes,
  formatMs,
  formatTimestampShortest,
} from "utils/format";

import moment from "moment";
import ExplainFingerprint from "components/ExplainFingerprint";
import { ComparablePlanType } from "components/WithExplainPlan";

type Props = {
  plan1: ComparablePlanType;
  plan2: ComparablePlanType;
};

const ExplainCompareSummary: React.FunctionComponent<Props> = ({
  plan1,
  plan2,
}) => {
  return (
    <div>
      <table className="text-base w-full">
        <thead>
          <tr>
            <td></td>
            <td className="text-center pb-2">Plan A</td>
            <td className="text-center pb-2">Plan B</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th className="text-[#606060] font-medium" scope="row">
              Seen At
            </th>
            <td className="text-right">
              {plan1.seenAt != null
                ? formatTimestampShortest(moment.unix(plan1.seenAt))
                : "-"}
            </td>
            <td className="text-right">
              {plan2.seenAt != null
                ? formatTimestampShortest(moment.unix(plan2.seenAt))
                : "-"}
            </td>
          </tr>
          <tr>
            <th className="text-[#606060] font-medium" scope="row">
              Plan Fingerprint
            </th>
            <td className="text-right">
              <ExplainFingerprint fingerprint={plan1.fingerprint} />
            </td>
            <td className="text-right">
              <ExplainFingerprint fingerprint={plan2.fingerprint} />
            </td>
          </tr>
          <tr>
            <th className="text-[#606060] font-medium" scope="row">
              Runtime
            </th>
            <td className="text-right">
              {plan1.runtime != null ? formatMs(plan1.runtime) : "-"}
            </td>
            <td className="text-right">
              {plan2.runtime != null ? formatMs(plan2.runtime) : "-"}
            </td>
          </tr>
          <tr>
            <th className="text-[#606060] font-medium" scope="row">
              I/O Read Time
            </th>
            <td className="text-right">
              {plan1.ioMs != null ? formatMs(plan1.ioMs) : "-"}
            </td>
            <td className="text-right">
              {plan2.ioMs != null ? formatMs(plan2.ioMs) : "-"}
            </td>
          </tr>
          <tr>
            <th className="text-[#606060] font-medium" scope="row">
              Read From Disk
            </th>
            <td className="text-right">
              {plan1.ioBytes != null ? formatBytes(plan1.ioBytes) : "-"}
            </td>
            <td className="text-right">
              {plan2.ioBytes != null ? formatBytes(plan2.ioBytes) : "-"}
            </td>
          </tr>
          <tr>
            <th className="text-[#606060] font-medium" scope="row">
              Total Est. Cost
            </th>
            <td className="text-right">
              {plan1.totCost != null ? formatNumber(plan1.totCost) : "N/A"}
            </td>
            <td className="text-right">
              {plan2.totCost != null ? formatNumber(plan2.totCost) : "N/A"}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ExplainCompareSummary;
